<template>
  <div>
    <article>
      <h3 class="center">{{ appName }}App儿童个人信息保护政策</h3>
      <p style="text-align: right">公布时间：2023年【08】月【03】日</p>
      <p style="text-align: right">生效时间：2023年【08】月【03】日</p>
      <p style="text-align: right">版本：第【一】版</p>
      <section>
        <h5>概述</h5>
        <p>
          成都超开心信息科技有限公司（以下简称“超开心公司”“{{
            appName
          }}”或“我们”）深知儿童（未满十四周岁）个人信息的重要性，并会全力保护使用我们产品或服务的儿童的个人信息安全。我们致力于维持监护人（以下简称“您”）对我们的信任，并将恪守以下原则，保护儿童的个人信息：权责一致原则、目的明确原则、选择同意原则、最小必要原则、公开透明原则、确保安全原则、主体参与原则等。同时，开心音符承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护儿童的个人信息。
        </p>
        <p>
          使用{{
            appName
          }}产品和/或服务前，请儿童及监护人务必仔细阅读并透彻理解本政策，特别是以粗体/下划线标识/斜体的条款应重点阅读，在确认充分理解并同意全部条款后再开始使用我们的产品和/或服务。
        </p>
      </section>
      <section>
        <h5>儿童特别说明：</h5>
        <p class="bold">
          如果您是不满十四周岁的儿童，请和您的监护人一起仔细阅读并透彻理解本政策，并征得您的监护人同意后，使用我们的产品、服务或向我们提供个人信息。
        </p>
      </section>
      <section>
        <h5>儿童监护人特别说明：</h5>
        <p class="bold">
          如果您是儿童用户的监护人，则儿童应在您的同意和指导下使用我们的服务和/或提供个人信息。如果您不同意本政策任何内容，您应要求您监护的儿童立即停止使用我们的产品和/或服务。<span>除非另有说明，不同意本政策或其更新（我们将及时通知您此类更新）可能会影响您监护的儿童正常使用或继续正常使用我们的产品和/或服务。</span>本政策进行更新后，我们会及时在“{{
            appName
          }}”平台发布最新版本，以便您及时查阅。
        </p>
      </section>
      <section>
        <p class="bold">
          除本隐私政策外，在特定场景下，我们将以及时告知的方式（包括但不限于更新本隐私政策、重新签署文件、页面提示、弹窗、站内信、邮件、网站公告或其他便于您获知的方式）另行向您详细说明对应信息的处理目的、方式、范围等规则。
        </p>
        <p>本政策将帮助您了解以下内容:</p>
        <ul>
          <li>一、 本政策的适用范围及个人信息处理依据</li>
          <li>二、 我们如何收集和使用儿童的个人信息</li>
          <li>三、 我们如何使用Cookie和同类技术</li>
          <li>四、 我们如何存储及保护儿童的个人信息</li>
          <li>五、 我们如何委托处理、共享、转让、公开披露儿童的个人信息</li>
          <li>六、 我们如何保护儿童的个人信息</li>
          <li>七、 儿童及监护人的权利</li>
          <li>八、 儿童的个人信息如何在全球范围转移</li>
          <li>九、 本政策如何更新</li>
          <li>十、 如何联系我们</li>
          <li>十一、 争议解决</li>
        </ul>
      </section>
      <section>
        <h5>相关术语及定义：</h5>
        <ul>
          <li>
            1. 用户或您：指“{{ appName }}”平台的注册用户；若“{{
              appName
            }}”平台产品或服务的实际使用人为注册用户的被监护人，则该等实际使用人也属于本协议项下的“您”“用户”或“您所监护的儿童”。
          </li>
          <li>
            2. “{{
              appName
            }}”平台：指成都超开心信息科技有限公司及其关联方开发、运营的互联网音乐平台，包括网页、APP客户端、公众号、小程序等。
          </li>
          <li>3. 超开心公司：指{{ appName }}产品和/或服务的运营方成都超开心信息科技有限公司及其关联公司。</li>
          <li>
            4.
            关联公司：指相互之间存在关联关系，关联关系是指超开心公司控股股东、实际控制人、董事、监事、高级管理人员与其直接或者间接控制的企业之间的关系，以及可能导致{{
              appName
            }}利益转移的其他关系。
          </li>

          <li>
            5.
            个人信息：是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。
          </li>
          <li>
            6.
            敏感个人信息：是指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息。
          </li>
          <li>7. 个人信息的处理：包括个人信息的收集、存储、使用、加工、传输、提供、公开、删除等。</li>
          <li>
            8.
            收集：指获得对个人信息的控制权的行为，包括由个人信息主体主动提供、通过与个人信息主体交互或记录个人信息主体行为等自动采集，以及通过共享、转让、搜集公开信息间接获取等方式。
          </li>
          <li>9. 删除：在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。</li>
          <li>10. 公开披露：向社会或不特定人群发布信息的行为。</li>
          <li>11. 共享：个人信息控制者向其他控制者提供个人信息，且双方分别对个人信息拥有独立控制权的过程。</li>
          <li>
            12.
            匿名化：通过对个人信息的技术处理，使得个人信息主体无法被识别或者关联，且处理后的信息不能被复原的过程。（注：个人信息经匿名化处理后所得的信息不属于个人信息）
          </li>
          <li>
            13. 去标识化：通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别或者关联个人信息主体的过程。
          </li>
        </ul>
      </section>
      <section>
        <h5>一、本政策的适用范围和个人信息处理依据</h5>
        <p class="bold">1. 适用范围</p>
        <p>
          本政策适用于超开心公司提供的产品和/或服务。儿童用户访问或登录相关客户端使用我们提供的产品和/或服务，均适用本政策。
        </p>
        <p>
          需要提醒您注意，本政策仅适用于我们所收集的信息，不适用于通过我们的产品和/或服务而接入的第三方产品和/或服务（包括任何第三方网站）收集的信息以及通过在我们产品和/或服务中进行广告服务的其他公司或机构所收集的信息。
        </p>
        <p class="bold">2. 个人信息处理依据</p>
        <p class="bold">
          除如下情形外，我们对您所监护儿童的个人信息的处理均应取得您明确同意（包含依据有关法律、行政法规规定应当取得您的单独同意或者书面同意）：
        </p>
        <ul>
          <li>
            （1）
            为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
          </li>
          <li>（2） 为履行法定职责或者法定义务所必需；</li>
          <li>（3） 为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</li>
          <li>（4） 为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</li>
          <li>（5） 依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；</li>
          <li>（6） 法律、行政法规规定的其他情形。</li>
        </ul>
      </section>
      <section>
        <h5>二、我们如何收集和使用儿童的个人信息</h5>
        <ul>
          <li class="bold">
            （一）我们没有专门针对儿童提供产品或服务，在用户使用我们的大部分产品或服务时，我们没有要求用户必须进行实名认证。因此，我们主要依靠用户在使用特定产品或服务时进行的实名认证或是否选择“青少年模式”来判断用户是否属于儿童。在用户没有进行实名认证或没有选择“青少年模式”前，我们将按照
            <a :href="privacyUrl">《{{ appName }}APP个人信息保护政策》</a>规定对用户个人信息进行保护。
          </li>
          <li>
            （二）当我们识别出用户为儿童时，我们可能会收集监护人的联系方式<span class="bold"
              >（手机号码、电子邮箱）</span
            >，并联系监护人以验证其与儿童的监护关系。
          </li>
          <li>
            （三）当用户为儿童时，我们也将依据有关法律法规规定，提示儿童应在获得监护人同意之后才允许儿童继续使用服务。
          </li>
          <li>
            （四）请您知悉，根据有关法律法规的规定，我们不会向儿童用户（我们已识别为儿童用户时）提供直播、打赏、充值和消费、充值会员以及虚拟资产等服务，还会对儿童用户使用产品进行使用时长限制和使用时段限制（具体青少年模式下不能使用的功能或服务、有关使用限制，我们将通过APP特定页面告知用户）。
          </li>
          <li>
            （五）有关儿童敏感个人信息的提示
            <p>
              我们不会主动要求儿童向我们提供敏感个人信息。<u>请监护人和儿童谨慎并留意儿童个人敏感信息的提供情况，</u>监护人同意儿童相关个人敏感信息我们可以按本政策所述的目的和方式来处理。
            </p>
            <p>如我们需要超出上述范围收集和使用儿童个人信息，我们将再次征得监护人的同意。</p>
            <p>如您选择不提供上述信息，您所监护儿童可能无法注册成为我们的用户或无法享受我们提供的某些产品和/或服务。</p>
          </li>
          <li>
            <p class="bold">（六）以下情形中，使用您所监护的儿童的个人信息无需您的同意：</p>
            <p class="bold">1.与个人信息控制者履行法律法规规定的义务相关的；</p>
            <p class="bold">2.与国家安全、国防安全直接相关的；</p>
            <p class="bold">3.与公共安全、公共卫生、重大公共利益直接相关的；</p>
            <p class="bold">4.与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
            <p class="bold">5.出于维护您所监护的儿童或其他个人的生命、财产等重大合法权益但又很难得到您同意的；</p>
            <p class="bold">6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
            <p class="bold">7.根据您的要求签订和履行合同所必需的；</p>
            <p class="bold">
              8.用于维护所提供的产品和/或服务的安全稳定运行所必需的，包括发现、处置产品和/或服务的故障；
            </p>
            <p class="bold">9.为合法的新闻报道所必须的；</p>
            <p class="bold">
              10.出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
            </p>
            <p class="bold">11.法律法规规定的其他情形。</p>
          </li>
        </ul>
      </section>
      <section>
        <h5 class="bold">三、我们如何使用Cookie和同类技术</h5>
        <p>
          Cookie
          和设备信息标识等同类技术是互联网中普遍使用的技术。我们可能通过Cookies或同类技术收集您的设备型号、操作系统、唯一设备标识符、登录IP地址信息、以及缓存您的浏览信息、点击信息。我们向您发送Cookies是为了保障产品与服务的安全、高效运转，我们可能会设置认证与保障安全性的Cookie
          或匿名标识符，使我们确认你是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。此外，还可以简化您重复登录的步骤实现登录时无需重复输入用户名及密码信息，并帮助判断您的登录状态以及账户或数据安全。我们承诺，不会将
          Cookies用于本政策所述目的之外的任何用途。
        </p>
      </section>
      <section>
        <h5>四、我们如何存储及保护儿童的个人信息</h5>
        <p>
          我们会采取加密等技术措施存储儿童个人信息，确保信息安全。我们仅在本政策所述目的所必须期间和法律法规及监管规定的时间限内存储儿童的个人信息。我们依照法律法规的规定，将儿童的个人信息存储于中华人民共和国境内。除法律或相关法规另有约定外，超开心公司在为提供服务之目的所必需的期间内保留儿童的个人信息，但您要求我们立即删除或注销账户的、或法律法规另有规定的除外。
        </p>
        <p>
          一般情况下，我们只会在为实现服务目的所必需的时间内或法律法规规定的条件下存储儿童个人信息，并在到期后删除或匿名化处理。您可以自主选择删除播放历史、歌单、订阅等您在使用{{
            appName
          }}服务时提供的和产生的个人信息，如果您选择删除上述信息，我们也将相应删除相应的个人信息。监护人可代替儿童单独进行个人信息的查询访问、删除及修改，为保障儿童个人信息的相关权利我们在产品开发中为监护人提供了对应的操作功能。
        </p>
        <p>
          如果我们终止服务或运营，我们将及时停止继续收集儿童个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并终止服务或运营后对儿童的个人信息进行删除或匿名化处理，法律法规或监管部门另有规定的除外。
        </p>
      </section>
      <section>
        <h5>五、我们如何委托处理、共享、转让、公开披露儿童的个人信息</h5>
        <p>
          我们充分知晓因违法共享、公开披露个人信息对个人信息主体造成损害时所应承担的法律责任，我们对儿童的信息承担保密义务，不会出售或出租儿童的任何信息，对于儿童的个人信息的一切委托处理、共享、公开披露，我们将严格按照以下条款进行：
        </p>
        <p>（一）委托处理</p>
        <p>
          为了提高效率、降低成本，我们可能会委托第三方专业服务提供商代表我们来处理信息，我们可能会聘请服务提供商为我们提供基础设施技术服务、客服服务、广告服务，内容服务，增值服务。对我们委托处理个人信息的公司、组织和个人，我们会通过书面协议、现场审计等方式要求其遵守严格的保密义务并采取有效的保密措施，要求其只能在委托范围内处理儿童的信息，而不得出于其自身利益使用儿童的信息。
        </p>
        <p>（二）共享</p>
        <p>1. 我们不会与其他公司、组织和个人共享您所监护的儿童个人信息，除非单独获得您的明确同意；</p>
        <p>
          2.
          在用户主动或需要使用特定产品或服务进行实名认证前，我们处理的用户个人信息可能包含能够间接识别到儿童的个人信息。对于此类信息，受限于技术手段，我们将按照<span
            class="bold"
            >《{{ appName }}APP个人信息保护政策》</span
          >的规定对个人信息实施保护。
        </p>
        <p>3. 我们不会与其他公司、组织和个人共享您所监护儿童的个人信息，但以下情况除外：</p>
        <p>（1） 在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享儿童的个人信息。</p>
        <p>
          （2）
          与授权合作伙伴共享：我们仅会出于本政策声明的合法、正当、必要、特定、明确的目的共享您所监护儿童的个人信息，授权合作伙伴只能接触到其提供的产品或服务所需信息，且我们会要求其不得将此信息用于其他任何目的，与授权合作伙伴约定严格的数据保护措施，并按照法律法规及不低于本政策所要求的标准保护您所监护儿童的个人信息。
        </p>
        <p>（3） 目前，我们的授权合作伙伴包括以下类型：</p>
        <p>
          a.
          广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您所监护儿童的个人身份信息（指可以识别您身份的信息，通过这些信息可以联系到您或识别您的身份）与提供广告、分析服务的合作伙伴共享。我们会委托这些合作伙伴处理与广告覆盖面和有效性相关的信息，但不会提供您的个人身份信息，或者我们将这些信息进行去标识化处理，以便它不会识别您个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据相结合，以执行我们委托的广告服务或决策建议。
        </p>
        <p>
          b. 业务供应商、服务提供商。我们将您所监护儿童的<span class="bold">设备信息、用户ID信息</span
          >发送给支持我们业务的供应商、服务提供商，用于提供SaaS营销或统计版权音乐播放情况等。
        </p>
        <p>
          （4）
          其他第三方服务。其他第三方服务包括福利活动、现金提现、客服服务、彩铃服务等由第三方提供的服务。儿童用户通过平台跳转到第三方服务，我们可能在获得儿童用户授权同意后向第三方共享儿童用户ID、设备识别码（包括IMSI、IMEI信息）、支付宝账号、微信ID、真实姓名以及其他为儿童用户提供服务所必须的信息，具体共享的信息以实际产品界面呈现和/或对应的授权协议的为准。其他第三方服务是否收集您所监护儿童的个人信息以及收集您所监护儿童的个人信息的范围，由其他第三方服务展示的页面为准。
        </p>
        <p>
          （5）
          为了使您能够使用上述服务和功能，我们的应用中会嵌入授权合作方的SDK或其他类似的应用程序。我们会对此类接入的SDK或其他类似应用程序进行严格的安全监测，通过协议约束形式以保障数据安全。
        </p>
        <p>
          （6） 上述第三方共享的合作方及共享信息清单，以及{{ appName }}APP中接入的第三方SDK或其他类似的应用程序如下：<a
            :href="childSDKUrl"
            >请点击查看</a
          >。
        </p>

        <p>（三）公开披露</p>
        <p>我们仅会在以下情形下，公开披露您所监护的儿童的个人信息：</p>
        <p>1. 获得您明确同意后；</p>
        <p>
          2.
          基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露儿童的个人信息，在符合法律法规的前提下，当我们收到上述披露个人信息的请求时，我们会要求接收方必须具有与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的个人信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据；
        </p>
        <p>
          3.
          因我们正常管理秩序维护需要，对违规账号、欺诈等严重损害超开心公司合法权益的行为进行处罚公告，公告信息主要包括用户账号、用户违规行为。
        </p>
        <p>（四）转让</p>
        <p>我们不会将您所监护的儿童个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
        <p>1. 在获得您的明确同意后，我们会向其他方转让您所监护儿童的个人信息；</p>
        <p>2. 根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</p>
        <p>
          3.
          在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您所监护儿童的个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
        </p>
        <p class="bold">（五）共享、公开披露儿童个人信息授权同意的例外情形</p>
        <p class="bold">根据法律法规的规定，在下述情况下，共享、转让、公开披露儿童个人信息无需事先征得您的授权同意：</p>
        <p class="bold">1. 与我们履行法律法规规定的义务相关的；</p>
        <p class="bold">2. 与国家安全、国防安全直接相关的；</p>
        <p class="bold">3. 与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p class="bold">4. 与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
        <p class="bold">5. 出于维护您所监护的儿童或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的。</p>
        <p class="bold">6. 您自行向社会公众公开的个人信息；</p>
        <p class="bold">7. 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
        <p class="bold">
          8.
          根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
        </p>
      </section>

      <section>
        <h5>六、我们如何保护儿童的个人信息</h5>
        <p>我们非常重视个人信息安全，并采取一切合理可行的措施，保护儿童的个人信息：</p>
        <p>
          1.
          我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您所监护儿童的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。
        </p>
        <p>2. 我们已经取得了以下认证：我们的核心业务系统通过了中华人民共和国网络安全等级保护三级认证的备案与评测。</p>
        <p>
          3. 如我们停止“{{
            appName
          }}”平台相关业务，我们将及时停止收集您所监护儿童的个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您所监护儿童的个人信息进行删除或匿名化处理。
        </p>
        <p>
          4.
          我们采取物理、技术和行政管理安全措施来降低个人信息丢失、误用、非授权访问、披露和更改的风险，包括但不限于传输层数据加密、防火墙和加密存储、物理访问控制以及信息访问授权控制。为此我们设置了安全程序保护您所监护儿童的个人信息不会被未经授权的访问所窃取。
        </p>
        <p>5. 我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
        <p>
          6.
          我们已制定相关的信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。
        </p>
        <p>
          7.
          互联网环境并非百分之百安全，我们将尽力确保您或您所监护儿童发送给我们的任何信息的安全性。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您及您所监护儿童告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您及/或您所监护儿童可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以APP推送通知、发送邮件/短消息、电话等方式告知您及您所监护儿童。难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。若您所监护儿童的合法权益受损，我们将承担相应的法律责任。
        </p>
        <p>
          8.
          互联网并非绝对安全的环境，我们强烈建议您及/或您所监护儿童通过安全方式、使用复杂密码，协助我们保证您的账号安全。如您及/或您所监护儿童发现自己的个人信息泄密，尤其是您及/或您所监护儿童的账户或密码发生泄漏，请您及/或您所监护儿童立即根据本政策中提供的联系方式联络我们，以便我们采取相应措施。但在我们知悉此种情况和在合理时间内采取行动前，我们对此不负任何责任。
        </p>
      </section>

      <section>
        <h5>七、儿童的个人信息如何在全球范围转移</h5>
        <p>原则上，我们在中华人民共和国境内收集和产生的儿童个人信息，将存储在中华人民共和国境内。</p>
        <p>
          但在我们认为必要的情况下，我们可能将儿童的个人信息传递至中国境外。如需跨境传输，我们将会按照相关法律法规的规定履行必要的诚信，并告知您境外接收方的身份、联系方式、处理目的、处理方式、个人信息的种类以及您向境外接收方行使法定权利的方式等事项，并取得您的同意，确保您的上述信息得到在中华人民共和国境内足够同等的保护。
        </p>
        <p>
          如您所监护儿童在境外使用我们的产品和/或服务，您或您所监护儿童将接受来自这些管辖区的访问。此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此情况下，我们会确保儿童的个人信息得到中华人民共和国境内足够同等的保护。
        </p>
      </section>

      <section>
        <h5>八、本政策如何更新</h5>
        <p>
          1.
          我们的儿童个人信息保护政策可能变更。未经您明确同意，我们不会削减您及您所监护的儿童按照本政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。
        </p>
        <p>
          2.
          对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过手机发送通知，说明个人信息保护政策的具体变更内容）。本政策所指的重大变更包括但不限于：
        </p>
        <p>（1） 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
        <p>（2） 我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>
        <p>（3） 个人信息共享、转让或公开披露的主要对象发生变化；</p>
        <p>（4） 您及您所监护的儿童参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
        <p>（5） 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
        <p>（6） 个人信息安全影响评估报告表明存在高风险时。</p>
      </section>

      <section>
        <h5>九、如何联系我们</h5>
        <p>如果您对本政策有任何疑问、意见或建议，可以通过如下任一方式联系我们：</p>
        <p>1. 发送邮件至【kuaiyinlive888@rings.net.cn】与我们儿童个人信息保护负责人联系。</p>
        <p>
          2.
          除此之外，您还可以将相关函件邮寄至下列地址：【中国（四川）自由贸易试验区成都高新区天府三街199号B区16层B3-2、B4、B5、B6单元】
        </p>
        <p>我们将尽快审核所涉问题，并在验证您的身份后，于十五天内进行回复。</p>
      </section>

      <section>
        <h5>十、争议解决</h5>
        <p>1. 如果您认为我们的个人信息处理行为损害了您或您所监护的儿童的合法权益，您可向有关政府部门反映。</p>
        <p>
          2.
          本政策以及我们处理儿童个人信息事宜引起的争议，您还可以通过向成都超开心信息科技有限公司住所地有管辖权的人民法院提起诉讼的方式寻求解决。
        </p>
      </section>
    </article>
  </div>
</template>

<script>
  import data from './data/child-protection-policy.json';

  export default {
    name: 'ChildProtectionPolicy',
    components: { },
    props: {},
    data() {
      return {
        childSDKUrl: 'https://landing.chaokanxin.net/sdk-list-less',
        privacyUrl: `https://landing.chaokaixin.net/privacy-protocol-less`,
        tableData: data.tableData,
        option: data.option,
        content: '',
        appName: '',
        appNameMap: new Map([
          ['less', { appName: 'Less' }],
        ]),
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      let app_name = 'less';

      this.privacyUrl += `?app_name=${app_name}`;
      this.childSDKUrl += `?app_name=${app_name}`;

      this.appName = this.appNameMap.get(app_name).appName;
      document.title = `${this.appName}App儿童个人信息保护政策`;
    },
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    /*padding-left: constant(safe-area-inset-left);*/
    /*padding-left: env(safe-area-inset-left);*/
    /*padding-right: constant(safe-area-inset-right);*/
    /*padding-right: env(safe-area-inset-right);*/
    /*padding-bottom: constant(safe-area-inset-bottom);*/
    /*padding-bottom: env(safe-area-inset-bottom);*/
  }

  article {
    margin: 0 4%;
  }

  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    margin: 12px 0;
    font-size: 13px;
  }
  .bold {
    font-weight: bold;
  }
  .center {
    text-align: center;
  }
  ul > li {
    margin: 5px 0;
    font-size: 13px;
    list-style: none;
  }
  ul > li > span {
    display: inline-block;
    margin: 4px 0;
  }
  ul > li > span.bold {
    display: inline;
  }
  i {
    font-style: italic;
  }
  .table {
    margin: 15px 0;
    border: 1px solid #aaa;
    width: 100%;
    border-bottom: 0;
  }
  .t-head {
    background: #c6c6c6 no-repeat scroll 0 0;
  }
  .t-head ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .t-head ul li {
    padding: 2px;
    width: 20%;
    text-align: center;
  }
  .t-body {
    background-color: #fff;
  }
  .t-body ul {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #aaaaaa;
  }
  .t-body ul li {
    position: relative;
    width: 20%;
    padding: 4px;
    text-align: center;
    word-break: break-all;
  }
  a {
    color: #007aff;
    text-decoration: underline;
  }
</style>
